import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFDatePicker({ name, label, children, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={onChange}
            value={value || null}
            label={label}
            ref={ref}
            format="DD-MM-YYYY"
            renderInput={(params) => (
              <TextField
                sx={{ width: '100%' }}
                {...params}
                error={Boolean(fieldState.error)}
                helperText={fieldState?.error?.message}
              />
            )}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
}
