import React, { useEffect, useState } from 'react';
// @mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import printJS from 'print-js';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// components
import TableNoData from './table/TableNoData';
import exportCSVFile from '../utils/JSONtoCSVService';

// @mui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import tableDateFilter from '../services/TableDateFilterService';
import { filter, forEach, sortBy } from 'lodash';
import TableMoreMenuComponent from './TableMoreMenu.js';
import { fCurrency } from '../utils/formatNumber';
import { InputAdornment, TextField } from '@mui/material';
import Iconify from './Iconify';

const TableComponent = ({
  columns,
  data,
  title,
  tableContainerSx,
  isDisabledPrint,
  handleEdit,
  handleDelete,
  isDisabledMoreMenu,
  isDisabledDatePicker,
  searchOptions,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const theme = useTheme();

  const [isAscendingDates, setIsAscendingDates] = useState(true);

  const [search, setSearch] = useState('');

  const searchFilterList = filter(data, (_user) => {
    return (
      get(_user, searchOptions.field)
        ?.toLowerCase?.()
        ?.indexOf?.(search?.toLowerCase?.()) !== -1
    );
  });

  const sortFilterTableBody = isAscendingDates
    ? searchFilterList
    : searchFilterList?.toReversed();

  const ignoreFields = ['id', 'newPassword'];

  const triggerPrint = () => {
    const printHeader = [];
    const printBody = [];

    forEach(columns, (__headerCell) => {
      if (!ignoreFields.includes(get(__headerCell, 'field'))) {
        printHeader.push({
          field: get(__headerCell, 'field'),
          displayName: get(__headerCell, 'title'),
        });
      }
    });

    forEach(sortFilterTableBody, (_row) => {
      const row = {};
      forEach(columns, (_headerCell) => {
        if (!ignoreFields.includes(get(_headerCell, 'field'))) {
          let value = get(_row, `${get(_headerCell, 'field')}`) || '--';
          if (get(_headerCell, 'field') === 'active') {
            value = get(_row, `${get(_headerCell, 'field')}`)
              ? 'Active'
              : 'Inactive';
          }
          row[get(_headerCell, 'field')] = value;
        }
      });
      printBody.push(row);
    });

    printJS({
      printable: printBody,
      type: 'json',
      properties: printHeader,
      header: `<h3 class="custom-h3">${title}</h3>`,
      gridStyle:
        'text-align:center;border: 1px solid lightgray; margin-bottom: -1px;padding-bottom:4px; padding-top:4px;',
    });
  };
  return (
    <Box>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          justifyContent: {
            md: 'end',
            xs: 'start',
          },
          mx: 3,
        }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            justifyContent: 'flex-end',
            width: '100%',
          }}
          gap={3}>
          <TextField
            fullWidth
            placeholder={`Search ${searchOptions.title}...`}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            InputProps={{
              sx: {
                height: 46,
                width: {
                  xs: '100%',
                  md: '20rem',
                },
                justifyContent: 'flex-start',
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />

          {!isDisabledDatePicker && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                format="DD-MM-YYYY"
                sx={{
                  '& input': {
                    padding: '12px 14px',
                  },
                  '& .MuiInputLabel-root': {
                    bottom: '29px',
                    top: 'unset',
                  },
                  width: {
                    xs: '100%',
                    md: '20rem',
                  },
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {
                      justifyContent: 'flex-end',
                      paddingBottom: '1rem',
                    },
                  },
                }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                format="DD-MM-YYYY"
                sx={{
                  '& input': {
                    padding: '12px 14px',
                  },
                  '& .MuiInputLabel-root': {
                    bottom: '29px',
                    top: 'unset',
                  },
                  width: {
                    xs: '100%',
                    md: '20rem',
                  },
                }}
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                    style: {
                      justifyContent: 'flex-end',
                      paddingBottom: '1rem',
                    },
                  },
                }}
              />
            </LocalizationProvider>
          )}

          {!isDisabledPrint && (
            <Stack
              direction="row"
              justifyContent={'center'}
              alignItems="center"
              gap={1}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                height: '3rem',
                marginLeft: {
                  md: 0,
                  xs: 'auto',
                },
              }}
              px={2}
              borderRadius={'10px'}>
              <Tooltip title="Download">
                <IconButton
                  onClick={() => {
                    const formatHeader = [];
                    const formatBody = [];

                    forEach(columns, (_item) => {
                      if (!ignoreFields.includes(get(_item, 'field'))) {
                        formatHeader.push(get(_item, 'title'));
                      }
                    });

                    forEach(sortFilterTableBody, (_row) => {
                      const row = {};
                      forEach(columns, (_headerCell) => {
                        if (!ignoreFields.includes(get(_headerCell, 'field'))) {
                          let value = get(_row, `${get(_headerCell, 'field')}`) || '--';
                          if (get(_headerCell, 'field') === 'active') {
                            value = get(_row, `${get(_headerCell, 'field')}`)
                              ? 'Active'
                              : 'Inactive';
                          }
                          row[get(_headerCell, 'field')] = value;
                        }
                      });
                      formatBody.push(row);
                    });

                    let fileName = kebabCase(title);

                    exportCSVFile(formatHeader, formatBody, fileName);
                  }}>
                  <DownloadIcon
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" sx={{ height: '26px' }} />
              <Tooltip title="Print">
                <IconButton onClick={triggerPrint}>
                  <PrintIcon
                    sx={{
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack mt={4} px={2.5} justifyContent={'center'} alignItems="center">
        <TableContainer sx={tableContainerSx}>
          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  '& .MuiTableCell-head': {
                    backgroundImage: theme.palette.secondary.main,
                  },
                }}>
                {map(columns, (_column, _index) => {
                  if (ignoreFields.includes(_column?.field)) return;
                  return (
                    <TableCell
                      sx={{ textAlign: _index === 0 ? 'start' : 'center' }}>
                      {get(_column, 'title') === 'Date' ? (
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          gap={1}
                          width="3rem"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            setIsAscendingDates(!isAscendingDates);
                          }}>
                          <Box> {get(_column, 'title')}</Box>
                          {isAscendingDates ? (
                            <ArrowDownwardIcon sx={{ fontSize: '20px' }} />
                          ) : (
                            <ArrowUpwardIcon sx={{ fontSize: '20px' }} />
                          )}
                        </Stack>
                      ) : (
                        get(_column, 'title')
                      )}
                    </TableCell>
                  );
                })}
                {!isDisabledMoreMenu && (
                  <TableCell sx={{ width: 14 }}></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {map(sortFilterTableBody, (_row) => {
                return (
                  <TableRow>
                    {map(columns, (_column, _columnIndex) => {
                      if (ignoreFields.includes(_column?.field)) return;
                      let value = _row[_column?.field];
                      if (_column?.field === 'active') {
                        value = _row[_column?.field] ? 'Active' : 'Inactive';
                      }
                      return (
                        <TableCell
                          sx={{
                            textAlign: _columnIndex === 0 ? 'start' : 'center',
                          }}>
                          {value || '--'}
                        </TableCell>
                      );
                    })}
                    {!isDisabledMoreMenu && (
                      <TableCell align="right">
                        <TableMoreMenuComponent
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                          _row={_row}
                          columns={columns}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              <TableNoData
                isNotFound={isEmpty(sortFilterTableBody)}
                text="No Records"
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
};

export default TableComponent;
