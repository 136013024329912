import { PATH_DASHBOARD } from '../routes/paths';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import GroupIcon from '@mui/icons-material/Group';
import AuthService from '../services/AuthService';
import useAuth from './useAuth';
// ----------------------------------------------------------------------

const useNavConfig = () => {
  const { isAuthenticated } = useAuth();

  const isAdmin = isAuthenticated && AuthService.isAdmin();
  const isUser = isAuthenticated && AuthService.isUser();

  const adminScreens = [
    {
      title: 'Dashboard',
      path: PATH_DASHBOARD.dashboard,
      icon: <DashboardIcon />,
    },
    {
      title: 'Entry',
      path: PATH_DASHBOARD.entry,
      icon: <LocalShippingIcon />,
    },
    {
      title: 'Exit',
      path: PATH_DASHBOARD.exit,
      icon: <AssignmentReturnIcon />,
    },
    {
      title: 'Users',
      path: PATH_DASHBOARD.users,
      icon: <GroupIcon />,
    },
  ];

  const userScreens = [
    {
      title: 'Entry',
      path: PATH_DASHBOARD.entry,
      icon: <LocalShippingIcon />,
    },
    {
      title: 'Exit',
      path: PATH_DASHBOARD.exit,
      icon: <AssignmentReturnIcon />,
    },
  ];

  let displayScreens = [];

  if (isAdmin) {
    displayScreens = adminScreens;
  } else if (isUser) {
    displayScreens = userScreens;
  }

  const navConfig = [
    {
      items: displayScreens,
    },
  ];
  return navConfig;
};

export default useNavConfig;
