import * as React from 'react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';

import { FormProvider, RHFTextField } from '../../components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../components/Iconify';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import MessageConstants from '../../constants/MessageConstants';
import AuthService from '../../services/API/AuthService';
import LoadingButton from '@mui/lab/LoadingButton';
import { IconButton, InputAdornment, useTheme } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  p: 4,
  width: {
    xs: '70%',
    md: '50%',
    lg: 450,
  },
  boxShadow: 'none',
  borderRadius: 1,
  outline: 'none',
};

export default function AddUser({ getAllUsers }) {
  const theme = useTheme();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const VerifySchema = Yup.object().shape({
    userName: Yup.string().required('User Name is required'),
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    userName: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(VerifySchema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await AuthService.postUserSignupData({ ...data, roles: ['user'] });
      setModalOpen(false);
      reset();
      toast.success('added user successfully');
      getAllUsers();
    } catch (error) {
      toast.error('Failed to add user');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Iconify icon={'eva:plus-fill'} />}
        onClick={() => setModalOpen(true)}>
        New User
      </Button>

      <Modal
        sx={{
          outline: 0,
        }}
        open={modalOpen}
        onClose={() => {
          reset();
          setModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Typography variant="h4">Add New User</Typography>

              <RHFTextField name="userName" label="User Name" />
              <RHFTextField name="email" label="Email Address" />
              <RHFTextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end">
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Stack
                gap={2}
                direction="row"
                justifyContent={'flex-end'}
                alignItems="center">
                <Button
                  variant="text"
                  sx={{ color: '#000' }}
                  onClick={() => {
                    reset();
                    setModalOpen(false);
                  }}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  sx={{
                    '& .css-11d3pii-MuiLoadingButton-loadingIndicator': {
                      color: theme.palette.primary.main,
                    },
                    '&.MuiButton-root': {
                      border: `1px solid ${theme.palette.primary.main}`,
                    },
                  }}
                  type="submit"
                  variant="outlined">
                  submit
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
