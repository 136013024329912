import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import RouteConstants from '../constants/RouteConstants';
import AuthService from '../services/AuthService';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (isAuthenticated) {
    let navbarNames = [];

    if (AuthService.isAdmin()) {
      navbarNames = [
        RouteConstants.DASHBOARD,
        RouteConstants.ENTRY,
        RouteConstants.EXIT,
        RouteConstants.USERS,
      ];
    } else if (AuthService.isUser()) {
      navbarNames = [RouteConstants.ENTRY, RouteConstants.EXIT];
    }

    return (
      <>
        {navbarNames.includes(location.pathname?.slice?.(1)) ? (
          children
        ) : (
          <Navigate to="/404" />
        )}
      </>
    );
  }

  return (
    <Navigate to={`${RouteConstants.ROOT_AUTH}/${RouteConstants.LOGIN}`} />
  );
}
