import RouteConstants from '../constants/RouteConstants';

// ----------------------------------------------------------------------

function path(root, sublink) {
  return `/${root}/${sublink}`;
}

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: RouteConstants.ROOT_AUTH,
  sign_in: path(RouteConstants.ROOT_AUTH, RouteConstants.SIGN_IN),
  edit: path(RouteConstants.ROOT_AUTH, RouteConstants.EDIT),
  register: path(RouteConstants.ROOT_AUTH, RouteConstants.REGISTER),
  sign_up: path(RouteConstants.ROOT_AUTH, RouteConstants.SIGN_UP),
  loginUnprotected: path(RouteConstants.ROOT_AUTH, 'login-unprotected'),
  registerUnprotected: path(RouteConstants.ROOT_AUTH, 'register-unprotected'),
  verify: path(RouteConstants.ROOT_AUTH, 'verify'),
  resetPassword: path(RouteConstants.ROOT_AUTH, 'reset-password'),
  newPassword: path(RouteConstants.ROOT_AUTH, 'new-password'),
};

export const PATH_DASHBOARD = {
  dashboard: RouteConstants.DASHBOARD,
  entry: RouteConstants.ENTRY,
  exit: RouteConstants.EXIT,
  users: RouteConstants.USERS,
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
