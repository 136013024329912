export default {
  IMAGE_UPLOAD_LIMIT: 'Only a single image can be uploaded!',
  AIRDROP_CONTENT_REQUIRED: 'Airdrop content is required!',
  AIRDROP_CREATED_SUCCESSFULLY: 'Airdrop created successfully!',
  AIRDROP_EDITED_SUCCESSFULLY: 'Airdrop edited successfully!',
  FAILED_TO_CREATE_AIRDROP: 'Failed to create Airdrop!',
  FAILED_TO_EDIT_AIRDROP: 'Failed to edit Airdrop!',
  FAILED_TO_FETCH_AIRDROP_DATA: 'Failed to fetch Airdrop data!',
  INVALID_DATE: 'Invalid Date',
  STOCK_IS_NOT_AVAILABLE: 'Stock is not available',
  FULL_WEIGHT_SHOULD_NOT_LESS_THAN_OR_EQUAL_TO_EMPTY_WEIGHT:
    'Full Weight should not less than or equal to Empty weight',
  ENTRY_ADDED_SUCCESSFULLY: 'Entry Added Successfully!',
  FAILED_TO_ADD_ENTRY: ' Failed to Add Entry!',
  EXIT_ADDED_SUCCESSFULLY: 'Exit Added Successfully!',
  FAILED_TO_ADD_EXIT: ' Failed to Add Exit!',
  SOMETHING_WENT_WRONG: ' Something went wrong',
};
