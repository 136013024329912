// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ROOT_AUTH: 'auth',
  SIGN_IN: 'sign_in',
  SIGN_UP: 'sign_up',
  APP: 'app',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  ENTRY: 'entry',
  EXIT: 'exit',
  USERS: 'users',
};
