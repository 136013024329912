import { format, getTime, formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';

// ----------------------------------------------------------------------

export function currentDate() {
  return dayjs().format('DD-MM-YYYY');
}

export function removeTimeFromDate(date) {
  return String(date)?.split(' ')[0];
}

export function FORMAT_YEAR_MONTH_DATE(date) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function fDate(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return date
    ? dayjs(date).format('YYYY-MM-DD hh:mm A')
    : dayjs().format('YYYY-MM-DD hh:mm A');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
