import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import RouteConstants from '../constants/RouteConstants';
import Users from '../pages/Users';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={
        <LoadingScreen isDashboard={pathname.includes('/dashboard')} />
      }>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {


 



  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <Navigate
              to={`/${RouteConstants.ROOT_AUTH}/${RouteConstants.LOGIN}`}
              replace
            />
          ),
          index: true,
        },
      ],
    },

    {
      path: RouteConstants.ROOT_AUTH,
      children: [
        {
          path: RouteConstants.LOGIN,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: RouteConstants.DASHBOARD,
          element: <Dashboard />,
        },
        {
          path: RouteConstants.ENTRY,
          element: <Entry />,
        },
        {
          path: RouteConstants.EXIT,
          element: <Exit />,
        },
        {
          path: RouteConstants.USERS,
          element: <Users />,
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
const Login = Loadable(lazy(() => import('../pages/Login')));
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Entry = Loadable(lazy(() => import('../pages/Entry')));
const Exit = Loadable(lazy(() => import('../pages/Exit')));

const Page404 = Loadable(lazy(() => import('../pages/Page404')));
