import get from 'lodash/get';
import ObjectStorage from './ObjectStorageService';

const AuthService = {
  isLoggedIn() {
    const data = ObjectStorage.getItem('accessToken', {});
    return !!data;
  },
  getToken() {
    const data = ObjectStorage.getItem('accessToken', {});
    return data;
  },
  getUserDetails() {
    const data = ObjectStorage.getItem('userData', {});
    return data;
  },
  isAdmin() {
    const data = ObjectStorage.getItem('userData', {});
    return get(data, 'roles.0') === 'ROLE_ADMIN';
  },
  isUser() {
    const data = ObjectStorage.getItem('userData', {});
    return get(data, 'roles.0') === 'ROLE_USER';
  },
  logout() {
    ObjectStorage.removeItem('accessToken');
    ObjectStorage.removeItem('userData');
    window.location.reload();
  },
};

export default AuthService;
