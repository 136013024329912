import * as React from 'react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import {
  FormProvider,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
} from '../components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { find, get, isEmpty, map } from 'lodash';
import RegexValidation from '../constants/RegexValidation';
import RHFDatePicker from './hook-form/RHFDatePicker';
import { forEach } from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from '../redux/store';
import { IconButton, InputAdornment, MenuItem, useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import MessageConstants from '../constants/MessageConstants';
import LoadingButton from '@mui/lab/LoadingButton';
import Iconify from './Iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  p: 4,
  width: {
    xs: '70%',
    md: '50%',
    lg: 450,
  },
  boxShadow: 'none',
  borderRadius: 1,
};

export default function TableRowEdit({
  editOpen,
  handleEditOpen,
  handleEditClose,
  handleCloseMenu,
  _row,
  columns,
  handleEdit,
}) {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const getDefaultValues = () => {
    const defaultValues = {};

    forEach(_row, (_value, _key) => {
      const ignoreKeys = ['id', 'date'];

      if (_value === '--' || ignoreKeys.includes(_key)) return;
      defaultValues[_key] = _value;
    });
    return defaultValues;
  };

  const getVerifySchema = () => {
    const verifySchema = {};

    const ignoreKeys = ['id', 'date'];

    forEach(_row, (_value, _key) => {
      if (_value === '--' || ignoreKeys.includes(_key)) return;

      const findLabel = find(columns, (_column) => {
        return get(_column, 'field') === _key;
      })?.title;

      verifySchema[_key] = Yup.string().required(`${findLabel} is required`);
    });
    return verifySchema;
  };

  const VerifySchema = Yup.object().shape(getVerifySchema());

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(VerifySchema),
    defaultValues: getDefaultValues(),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = (data) => {
    setLoading(true);
    handleEdit({
      selectedRow: _row,
      editedData: data,
      onClose: () => {
        handleEditClose();
        handleCloseMenu();
        setLoading(false);
        reset();
      },
    });
  };

  return (
    <>
      <Modal
        sx={{
          outline: 0,
        }}
        open={editOpen}
        onClose={handleEditOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Typography variant="h4">Edit</Typography>

              {map(_row, (_value, _key) => {
                const findLabel = find(columns, (_column) => {
                  return get(_column, 'field') === _key;
                })?.title;

                const ignoreKeys = ['id', 'date'];
                if (_value === '--' || ignoreKeys.includes(_key)) return;
                if (_key === 'newPassword')
                  return (
                    <RHFTextField
                      name={_key}
                      label={findLabel}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end">
                              <Iconify
                                icon={
                                  showPassword
                                    ? 'eva:eye-fill'
                                    : 'eva:eye-off-fill'
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                if (_key === 'active') {
                  return (
                    <RHFSwitch
                      name={_key}
                      label={findLabel}
                      labelPlacement="end"
                      sx={{
                        mb: 1,
                        mx: 0,
                        width: 1,
                        justifyContent: 'flex-start',
                      }}
                    />
                  );
                }
                return <RHFTextField name={_key} label={findLabel} />;
              })}

              <Stack
                gap={2}
                direction="row"
                justifyContent={'flex-end'}
                alignItems="center">
                <Button
                  variant="text"
                  sx={{ color: 'black' }}
                  onClick={() => {
                    reset();
                    handleEditClose();
                  }}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  sx={{
                    '& .css-11d3pii-MuiLoadingButton-loadingIndicator': {
                      color: theme.palette.primary.main,
                    },
                    '&.MuiButton-root': {
                      border: `1px solid ${theme.palette.primary.main}`,
                    },
                  }}
                  type="submit"
                  variant="outlined">
                  submit
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
