import React, { useState } from 'react';
import { TableMoreMenu } from '../components/table/index.js';
import Iconify from '../components/Iconify';
import { Divider, MenuItem, Typography } from '@mui/material';
import TableRowEdit from './TableRowEdit.js';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const TableMoreMenuComponent = ({
  handleEdit,
  handleDelete,
  _row,
  columns,
}) => {
  const [openMenu, setOpenMenuActions] = useState(null);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const [editOpen, setEditOpen] = React.useState(false);

  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);

  return (
    <>
      <TableMoreMenu
        open={openMenu}
        onOpen={handleOpenMenu}
        onClose={handleCloseMenu}
        actions={
          <>
            <MenuItem
              onClick={() => {
                handleEditOpen();
              }}>
              <Iconify icon={'bxs:edit'} />
              Edit
            </MenuItem>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
              sx={{ color: 'error.main' }}>
              <Iconify icon={'eva:trash-2-outline'} />
              Delete
            </MenuItem>
          </>
        }
      />
      <TableRowEdit
        editOpen={editOpen}
        handleEditOpen={handleEditOpen}
        handleEditClose={handleEditClose}
        handleCloseMenu={handleCloseMenu}
        _row={_row}
        columns={columns}
        handleEdit={handleEdit}
      />
      <Dialog
        sx={{ '& .MuiPaper-root': { borderRadius: '1px' } }}
        fullWidth
        maxWidth="xs"
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>
          <Typography variant="h5" mb={2}>
            Delete
          </Typography>
          <Typography gutterBottom>Are you sure to delete ?</Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            variant="text"
            color="inherit"
            onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleDelete({
                selectedRow: _row,
                onClose: () => {
                  setOpenMenuActions();
                  setDeleteDialogOpen(false);
                },
              });
            }}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableMoreMenuComponent;
