import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import RouteConstants from '../constants/RouteConstants';
import AuthService from '../services/AuthService';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    let path = '';

    if (AuthService.isAdmin()) {
      path = RouteConstants.DASHBOARD;
    } else if (AuthService.isUser()) {
      path = RouteConstants.ENTRY;
    }

    return <Navigate to={`/${path}`} />;
  }

  return <>{children}</>;
}
