// @mui
import { Card, Container, Stack, Typography } from '@mui/material';

import Page from '../components/Page';
import dayjs from 'dayjs';
import { fDateTimeSuffix, FORMAT_YEAR_MONTH_DATE } from '../utils/formatTime';
import Table from '../components/Table.js';
import React, { useEffect, useMemo } from 'react';
import AddUser from '../sections/Users/AddUser';
import useSettings from '../hooks/useSettings';
import AuthService from '../services/API/AuthService';
import { toast } from 'react-toastify';
import MessageConstants from '../constants/MessageConstants';
import { map } from 'lodash';
import { get } from 'lodash';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Users() {
  const { themeStretch } = useSettings;

  const [allUsers, setAllUsers] = React.useState([]);

  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  const columns = [
    {
      title: 'Date',
      field: 'date',
    },
    {
      title: 'ID',
      field: 'id',
    },
    {
      title: 'User name',
      field: 'userName',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'New password',
      field: 'newPassword',
    },
    {
      title: 'Status',
      field: 'active',
    },
  ];

  const data = useMemo(() => {
    return map(allUsers, (_user) => {
      return {
        date: fDateTimeSuffix(get(_user, 'updatedAt')),
        id: get(_user, 'id'),
        email: get(_user, 'email'),
        userName: get(_user, 'userName'),
        newPassword: '',
        active: get(_user, 'active'),
      };
    });
  }, [allUsers]);

  const getAllUsers = async () => {
    try {
      const res = await AuthService.getAllUsersData();
      setAllUsers(get(res, 'data'));
    } catch (error) {
      toast.error(error.message || MessageConstants.SOMETHING_WENT_WRONG);
    }
  };

  const getAllUsersByDate = async () => {
    try {
      const res = await AuthService.getAllUsersDataByDate(
        FORMAT_YEAR_MONTH_DATE(startDate),
        FORMAT_YEAR_MONTH_DATE(endDate)
      );
      setAllUsers(get(res, 'data'));
    } catch (error) {
      toast.error(error.message || MessageConstants.SOMETHING_WENT_WRONG);
    }
  };

  // useEffect(() => {
  //   getAllUsers();
  // }, []);


  const getAllUsersBaseOnCondition = () => {
    if(startDate && endDate){
      getAllUsersByDate()
    }else {
      getAllUsers()
    }
};

  useEffect(() => {
    getAllUsersBaseOnCondition()
  }, [startDate, endDate]);

  const handleEdit = async ({ selectedRow, editedData, onClose }) => {
    try {
      await AuthService.editUserData({
        id: get(selectedRow, 'id'),
        userName: get(editedData, 'userName'),
        email: get(editedData, 'email'),
        password: get(editedData, 'newPassword'),
        active: get(editedData, 'active'),
      });
      onClose();
      toast.success('user is edited successfully');
      getAllUsers();
    } catch (error) {
      toast.error('Failed to edit user');
    }
  };

  const handleDelete = async ({ selectedRow, onClose }) => {
    try {
      await AuthService.deleteUserData(get(selectedRow, 'id'));
      onClose();
      toast.success('user is deleted successfully');
      getAllUsers();
    } catch (error) {
      toast.error('Failed to delete user');
    }
  };

  return (
    <Page title="Users">
      <Container sx={{ mt: 1.5 }} maxWidth={themeStretch ? false : 'xxl'}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography mb={0} variant="h3" gutterBottom>
            Users List
          </Typography>
          <AddUser getAllUsers={getAllUsers} />
        </Stack>

        <Card sx={{ mt: 4, pt: 5 }}>
          <Table
            data={data}
            columns={columns}
            title="Users List"
            tableContainerSx={{
              width: '100%',
              height: 'calc(100vh - 19.5rem)',
            }}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            searchOptions={{ field: 'email', title: 'Email' }}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Card>
      </Container>
    </Page>
  );
}
