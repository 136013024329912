import APIService from '../APIService';

const API = `${process.env.REACT_APP_YARD_GATE}/api/v1`;

const AuthService = {
  postLoginData(data) {
    return new Promise((resolve, reject) => {
      APIService.fetch(
        `${API}/auth/signin`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  postUserSignupData(data) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/auth/signup`,
          method: 'POST',
          data: data,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  editUserData(data) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/admin/edit-user`,
          method: 'PUT',
          data: data,
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  deleteUserData(id) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/admin/delete-user?id=${id}`,
          method: 'DELETE',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  getAllUsersData() {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/admin/get-user`,
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
  getAllUsersDataByDate(startDate, endDate) {
    return new Promise((resolve, reject) => {
      APIService.request(
        {
          url: `${API}/admin/get-user?startDate=${startDate}&endDate=${endDate}`,
          method: 'GET',
        },
        (error, data) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(data);
        }
      );
    });
  },
};


export default AuthService;
